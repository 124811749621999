.lab-edit {
    //If we need to apply this style to other date-picker components outside of
    //lab-edit move the style up to app.
    .react-date-picker {
        display: flex;
        .react-date-picker__button {
            background-color: white; //@TODO pull colors out to vars
        }
    }

    button#blueprint_file {
        height: 21px;
        padding: 2px 8px 3px;
        width: 75px;
        font-size: 11px;
    }
    Input#blueprint_file {
        width: 80px;
    }
}
