.lab-view {
    .controls,
    table {
        margin: auto;
        width: 90%
    }

    th {
        width: 50%;
    }

    a {
        float: right;
        margin: 10px;
    }
}
