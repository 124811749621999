.lab-start {
    width: 70%;
    margin: 0 auto;

    label.form-label {
        margin-right: 10px;
    }

    #eventProductId {
        width: 400px;
    }
}