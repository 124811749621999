.expandable {
    margin-bottom: 1.25rem;

    .expandable-target {
        cursor: pointer;
    }
    .fa {
        padding-right: 10px;
    }
    .title.card-header {
        display: flex;
        justify-content: space-between;
    }
}
