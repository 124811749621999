.contact-us {
    .ask-a-question {
        position: fixed;
        bottom: 20px;
        right: 115px;
        button {
            background-image: none;
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
        }
        button:hover {
            color: #007bff;
            background-color: #fff;
            border-color: #007bff;
        }
    }
    textarea {
        width: 100%;
    }
}
