//http://tobiasahlin.com/spinkit/

@mixin buildDot($dot) {
    -webkit-transform: rotate(($dot - 1) * 30deg);
        -ms-transform: rotate(($dot - 1) * 30deg);
            transform: rotate(($dot - 1) * 30deg);
    &:before {
      -webkit-animation-delay: ($dot - 13) * 0.1s;
              animation-delay: ($dot - 13) * 0.1s;
    }
}

.spinner {
  width: 35px;
  height: 35px;
  position: relative;
  margin-top: -20px;

    .dot {
      width: 80%;
      height: 80%;
      position: absolute;
      left: 5px;
      top: 17px;
    }

    .dot:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: #333;
      border-radius: 100%;
      -webkit-animation: dotFadeDelay 1.2s infinite ease-in-out both;
              animation: dotFadeDelay 1.2s infinite ease-in-out both;
    }

    .dot2 { @include buildDot(2); }
    .dot3 { @include buildDot(3); }
    .dot4 { @include buildDot(4); }
    .dot5 { @include buildDot(5); }
    .dot6 { @include buildDot(6); }
    .dot7 { @include buildDot(7); }
    .dot8 { @include buildDot(8); }
    .dot9 { @include buildDot(9); }
    .dot10 { @include buildDot(10); }
    .dot11 { @include buildDot(11); }
    .dot12 { @include buildDot(12); }
}

@-webkit-keyframes dotFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes dotFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}
