.sortable-field {
  cursor: pointer;
  position: relative;
  &:after {
    position: absolute;
    right: 4px;
    font-family: 'Glyphicons Halflings';
    content: '\E151';
  }
}
.lab-tab {
    .well {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btn-success {
            height: 35px;
        }
    }
    #product_id {
        width: 50%;

    }
}
